//@flow
import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

export function ScrollToTop(): null {
  const {pathname} = useLocation();

  useEffect(() => {
    if (window.scrollX > 0 || window.scrollY > 0) {
      window.scrollTo(0, 0);
    }

    const main = document.getElementsByTagName("main")[0];
    if (main) {
      main.scrollTo(0, 0);
    }

    // setTimeout(() => window.scrollTo(0, 0), 100);
  }, [pathname]);

  return null;
}

export function useIsMount(): boolean {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
}

export function usePrevious(value: any): any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
