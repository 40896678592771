//@flow
import * as React from 'react';
import merge from 'deepmerge';
import Settings from 'config/settings.json';

const SessionStateContext: Object = React.createContext(null);
const SessionDispatchContext: Object = React.createContext(null);
const DEFAULT_SETTING_NAME = "default";
const MODEL_KEY = "model";
const COLOR_KEY = "color";

export type ContentsColorType = {
  ui: Object,
  text: Object,
  video: string
}

export type ContentsModelType = {
  name: string,
  colors: Array<string>,
  links: Object,
}

export type SettingsType = {
  version: string,
  country_code: string,
  path_rules: string,
  country_codes: Array<string>,
  color_codes: Array<string>,
  model_codes: Array<string>,
  contents: Object,
  models: Object,
}

export type StateType = {
  colorCode: string,
  modelCode?: string,
  originSettings: SettingsType,
  settings: SettingsType,
  contents: ContentsColorType,
  model?: ContentsModelType,
  error?: string,
}

type ActionType =
  | {type: 'SELECT_COUNTRY', data: Object}
  | {type: 'SELECT_COLOR', data: string}
  | {type: 'ERROR_MESSAGE', data: string}
;

function sessionReducer(state: StateType, action: ActionType) {
  if (process.env.NODE_ENV !== "production") {
    console.log('session', action, state)
  }
  switch (action.type) {
    case 'ERROR_MESSAGE':
        return {...state, error: action.data};
    case 'SELECT_COLOR':
        console.assert(state.settings !== null);
        console.assert(state.settings.contents[DEFAULT_SETTING_NAME] !== null);

      if (action.data === undefined || action.data === "") {
        const settings = state.settings;
        const paths = window.location.pathname.split("/");
        const urlPaths: Array<string> = settings.path_rules?.split("/");
        const colorCodeInPath = findValidPath(paths, urlPaths, COLOR_KEY)?.toLowerCase();
        const modelCodeInPath = findValidPath(paths, urlPaths, MODEL_KEY)?.toLowerCase();
        const colorCode = isValidColorCode({...state, settings: settings}, colorCodeInPath) ? (colorCodeInPath ?? "") : DEFAULT_SETTING_NAME;
        const modelCode = isValidModelCode({...state, settings: settings}, modelCodeInPath) ? modelCodeInPath : (modelCodeInPath === undefined ? undefined:"");
        const contents = merge(settings.contents[DEFAULT_SETTING_NAME], settings.contents[colorCode]);
        return {...state, colorCode:colorCode, modelCode: modelCode, contents: contents};
      }

      return {...state, colorCode: action.data, contents: state.settings.contents[action.data] ?? state.settings.contents[DEFAULT_SETTING_NAME]};
    case 'SELECT_COUNTRY':
      const datas = toLowerKeys(action.data);
      const settings = merge(state.originSettings, datas);
      //console.log(settings, state.originSettings, datas);
      const paths = window.location.pathname.split("/");

      const urlPaths: Array<string> = settings.path_rules?.split("/");
      const colorCodeInPath = findValidPath(paths, urlPaths, COLOR_KEY)?.toLowerCase();
      const modelCodeInPath = findValidPath(paths, urlPaths, MODEL_KEY)?.toLowerCase();
      const colorCode = isValidColorCode({...state, settings: settings}, colorCodeInPath) ? (colorCodeInPath ?? "") : DEFAULT_SETTING_NAME;
      const modelCode = isValidModelCode({...state, settings: settings}, modelCodeInPath) ? modelCodeInPath : (modelCodeInPath === undefined ? undefined:"");

      //console.log(paths, urlPaths, settings.country_code, ",", modelCode, modelCodeInPath, ",", colorCode);
      window.contentsVersion = settings.country_code + "-" + settings.version;

      const contents = merge(settings.contents[DEFAULT_SETTING_NAME], settings.contents[colorCode]);
      return {...state, colorCode:colorCode, modelCode: modelCode, settings: settings, contents: contents};
    default:
      return state;
  }
}

function sessionInit(): StateType {
  const settings = toLowerKeys(Settings);

  const paths = window.location.pathname.split("/");
  const colors = paths.filter(v => v && settings.color_codes.includes(v));
  const color = colors.length > 0 ? colors[0] : DEFAULT_SETTING_NAME;
  const models = paths.filter(v => v && settings.model_codes.includes(v));
  const model = models.length > 0 ? models[0] : undefined;

  return {colorCode: color, modelCode: model, originSettings: settings, settings: settings, contents: settings.contents[color]};
}

type Props = {
  children: React.Node
}

export default function Session(props: Props): React.Element<any> {
  const [state, dispatch] = React.useReducer(sessionReducer, {}, sessionInit);
  return (
    <SessionStateContext.Provider value={state}>
      <SessionDispatchContext.Provider value={dispatch}>
        {props.children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  );
}

export function useSessionState(): StateType {
  const state = React.useContext(SessionStateContext);
  if (!state) {
    throw new Error('Cannot find SessionProvider');
  }
  return state;
}

export function useSessionDispatch(): (ActionType) => void {
  const dispatch = React.useContext(SessionDispatchContext);
  if (!dispatch) {
    throw new Error('Cannot find SessionProvider');
  }
  return dispatch;
}

export function isValidCountryCode(session: StateType, countryCode?: string): boolean {
  return session.settings.country_codes.includes(countryCode?.toLowerCase());
}

export function isValidModelCode(session: StateType, modelCode?: string): boolean {
  return session.settings.model_codes.includes(modelCode?.toLowerCase());
}

export function isValidColorCode(session: StateType, colorCode?: string, defaultOk?: boolean): boolean {
  if (defaultOk === true && colorCode === "") {
    return true;
  }
  return session.settings.color_codes.includes(colorCode?.toLowerCase());
}

export function dispatchCountryCode(session: StateType, dispatch: Function, _countryCode: string) {
  const countryCode = _countryCode ? _countryCode.toLowerCase() : "";
  // if (!isValidCountryCode(session, countryCode)) {
  //   console.log(_countryCode, "is invalid country code!");
  //   return;
  // }

  if (session.settings.country_code === countryCode) {
    dispatch({type: 'SELECT_COLOR', data: ""});
    return;
  }

  fetchSettings(countryCode).then(res => res.json()).then(res => {
    if (!res.country_code) {
      res.country_code = countryCode;
    }
    dispatch({type: 'SELECT_COUNTRY', data: res});
  }).catch(error => {
    if (process.env.NODE_ENV !== "production") {
      console.warn(error);
    }
    dispatch({type: 'ERROR_MESSAGE', data: error.message})
  });
}

export function getVideoFilename(session: StateType, postFix: string): string {
  if (session.contents.video === undefined || session.contents.video === "") {
    return "";
  }
  
  const videoFilename = session.contents.video.replace(/\.[^/.]+$/, "");
  const ext = session.contents.video.split('.').pop();
  const videoFile = `${videoFilename}${postFix}.${ext}`;
  // if (process.env.NODE_ENV !== "production") {
  //   return (process.env.PUBLIC_URL ?? '') + `/contents/default/videos/${videoFile}`;
  // }
  return (process.env.PUBLIC_URL ?? '') + `/contents/${session.settings.country_code}/videos/${videoFile}`;
}

export function getPosterFilename(session: StateType): string {
  const filename = session.contents.video?.replace(/\.[^/.]+$/, "");
  if (!filename) {
    return "";
  }
  // if (process.env.NODE_ENV !== "production") {
  //   return (process.env.PUBLIC_URL ?? '') + `/contents/default/videos/${filename}.jpg`;
  // }
  return (process.env.PUBLIC_URL ?? '') + `/contents/${session.settings.country_code}/videos/${filename}.jpg`;
}

export function toLowerKeys(obj: Object): Object {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => {
      if (Array.isArray(v)) {
        const arr = v.map(a => (typeof a === 'string' || a instanceof String) ? a.toLowerCase() : a);
        return [k.toLowerCase(), arr];
      }
      if (typeof v === "object" && v != null) {
        const childObj = Object.fromEntries(Object.entries(v).map(([k,v]) => [k.toLowerCase(), v]));
        return [k.toLowerCase(), childObj];
      }
      if ((typeof v === 'string' || v instanceof String)) {
        return [k.toLowerCase(), v.toLowerCase()];
      }
      return [k.toLowerCase(), v]
    }
    )
  );
}

export async function fetchSettings(countryCode: string): Promise<any> {
  const res = await fetch(`${process.env.PUBLIC_URL ?? ""}/contents/${countryCode}/cmf_${countryCode}.json`);
  return res;
}

function findValidPath(paths: Array<string>, arr: Array<string>, key: string): ?string {
  //console.log(paths, arr, key);
  const idx = arr.findIndex(v => v === key.toLowerCase());
  if (idx >= 0) {
    return paths[idx + 2];
  }
  return undefined;
}
