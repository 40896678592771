//@flow
import {useLayoutEffect, useRef, useState} from 'react';
const _ = require('lodash');

export function useWindowSize(): Array<number> {
  const sizeRef = useRef(getWindowSize())
  const [size, setSize] = useState(sizeRef.current);
  const timer5ms = useRef(null);
  const timer1s = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      const windowSize = getWindowSize();
      if (sizeRef.current[0] !== windowSize[0] || sizeRef.current[1] !== windowSize[1]) {
        //console.log('resize', sizeRef.current, windowSize, (new Date()).getTime());
        sizeRef.current = windowSize;
        setSize(sizeRef.current);
        //window.scrollTo(0, 0);
      }
    }
    function handleLoad(e) {
      updateSize();
    }
    function handleResize(e) {
      updateSize();
    }
    function handleScroll(e) {
      //updateSize();
      // if (window.scrollX > 0 || window.scrollY > 0) {
      //   window.scrollTo(0, 0);
      // }
      // 모바일 디바이스에서 화면사이즈가 바뀌면서 자꾸 스크롤되고 있어서 강제로 원점으로 올려줌
      // if ((document?.scrollingElement?.scrollTop ?? 0) > 0) {
      //   document?.scrollingElement?.scrollTo(0,0);
      // }
    }
    const handleScrollDebounce = _.debounce(handleScroll, 150);

    function updateOrientation(e) {
      //setOrientation(e.target.screen?.orientation?.angle ?? (e.target.orientation ?? 0));
      if (timer5ms.current) {
        clearTimeout(timer5ms.current);
        timer5ms.current = null;
      }
      if (timer1s.current) {
        clearTimeout(timer1s.current);
        timer1s.current = null;
      }
      timer5ms.current = setTimeout(updateSize, 500);
      timer1s.current = setTimeout(updateSize, 1000);
    }

    window.addEventListener('load', handleLoad);
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScrollDebounce);
    window.addEventListener('orientationchange', updateOrientation);
    updateSize();
    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScrollDebounce);
      window.removeEventListener('orientationchange', updateOrientation);
    }
  }, []);
  return size;
}

export function getWindowSize(): Array<number> {
  if (window.navigator != null && window.navigator.userAgent != null && window.navigator.userAgent.indexOf("SamsungBrowser") >= 0 ) {
    return [Math.round(window.visualViewport.width * window.visualViewport.scale), Math.round(window.visualViewport.height * window.visualViewport.scale)];
  }
  else {
    // const vw = Math.round(window.visualViewport.width * window.visualViewport.scale);
    // const vh = Math.round(window.visualViewport.height * window.visualViewport.scale);
    // return [Math.min(vw, window.innerWidth), Math.min(vh , window.innerHeight)];
    return [window.innerWidth, window.innerHeight];
  }
}

export function useWindowOrientation(): number {
  const [orientation, setOrientation] = useState(-1);
  useLayoutEffect(() => {
    function updateOrientation(e) {
      setOrientation(e.target.screen?.orientation?.angle ?? (e.target.orientation ?? 0));
    }
    window.addEventListener('orientationchange', updateOrientation);
    return () => window.removeEventListener('orientationchange', updateOrientation);
  }, []);
  return orientation;
}
