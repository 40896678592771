//@flow
import * as React from 'react';
import {useWindowSize} from 'utils/windowUtils';
import { isMobileOnly as checkMobile, isMobile as checkTouch, isTablet as checkTablet } from 'react-device-detect';

type Props = {
  children?: React.Node,
};

export function isMobile(): boolean {
  return checkMobile;
}

export function isTablet(): boolean {
  return checkTablet;
}

export function isTouch(): boolean {
  return checkTouch;
}

export function useIsMobileAgent(): boolean {
  useWindowSize(); // for changing state
  return isMobile();
}

export function useIsTabletAgent(): boolean {
  useWindowSize(); // for changing state
  return isTablet();
}

export function useIsTouchAgent(): boolean {
  useWindowSize(); // for changing state
  return isTouch();
}

export function IsMobileAgent(props: Props): React.Node {
  const mobile = useIsMobileAgent();
  return mobile && props.children ? props.children : null;
}

export function IsNotMobileAgent(props: Props): React.Node {
  const mobile = useIsMobileAgent();
  return !mobile && props.children ? props.children : null;
}

export function IsTabletAgent(props: Props): React.Node {
  const tablet = useIsTabletAgent();
  return tablet && props.children ? props.children : null;
}

export function IsDesktopAgent(props: Props): React.Node {
  const mobile = useIsMobileAgent();
  const tablet = useIsTabletAgent();
  return !mobile && !tablet && props.children ? props.children : null;
}

export function IsTouchAgent(props: Props): React.Node {
  const touch = useIsTouchAgent();
  return touch && props.children ? props.children : null;
}

export function IsNonTouchAgent(props: Props): React.Node {
  const toucn = useIsTouchAgent();
  return !toucn && props.children ? props.children : null;
}
