// @flow
import * as React from 'react';
import styled, {createGlobalStyle, keyframes} from 'styled-components';
import {useLayoutState} from 'components/Layout';
import {useSessionState, getVideoFilename, getPosterFilename} from 'components/Session';
import { isMobile, IsTabletAgent } from 'components/Responsive';
import { VideoController } from 'components/VideoController';
import { UseMobileNativePlayer, TopBarHeight, ThemeBlackColor } from 'components/Constants';
import Logo from 'assets/images/common/logo_genesis.png';
import { PlayVideoIcon, PauseVideoIcon, ReplayVideoIcon } from 'components/Icons';

type Props = {
  children?: React.Node,
  contents?: Object,
  mobileFullScreen?: boolean,
  showModels?: boolean,
  onShowModels: () => void,
  onHideModels: () => void,
  onShowBlackout?: () => void,
};

export default function VideoPlayer(props: Props): React.Element<any> {
  // ---------- CALCULATE LAYOUT BEGIN ----------
  const session = useSessionState();
  const videoFile = React.useMemo(() => getVideoFilename(session, isMobile() ? "_mobile" : ""), [session]);
  const posterFile = React.useMemo(() => getPosterFilename(session), [session]);
  const videoAreaRef = React.useRef<?React.ElementRef<'div'>>(null);
  const videoRef = React.useRef<?React.ElementRef<'video'>>(null);
  const layoutState = useLayoutState();
  const videoSize = React.useMemo(() => [layoutState.videoWidth, layoutState.videoHeight], [layoutState]);
  const mobileFullScreen = props.mobileFullScreen;

  const visibleTopBar = React.useMemo(() => layoutState.mobile ? (TopBarHeight.mobile > 0) : (TopBarHeight.desktop > 0), [layoutState]);
  const topBarHeight = React.useMemo(() => visibleTopBar ? TopBarHeight.mobile : undefined, [visibleTopBar]);
  const videoAreaTop = React.useMemo(() => mobileFullScreen ? (layoutState.height - videoSize[1]) * 0.5 : topBarHeight, [mobileFullScreen, layoutState, videoSize, topBarHeight]);
  const controllerHeight = React.useMemo(() => layoutState.controllerHeight, [layoutState]);
  const controllerAreaHeightCalc = React.useMemo(() => mobileFullScreen ? (layoutState.height - videoSize[1]) * 0.5 : (layoutState.tablet ? layoutState.controllerHeight : 0), [mobileFullScreen, layoutState, videoSize]);
  const controllerAreaHeight = React.useMemo(() => {
    return controllerAreaHeightCalc >= controllerHeight ? controllerAreaHeightCalc : controllerHeight;
  }, [controllerAreaHeightCalc, controllerHeight]);
  const notEnoughtHeight = React.useMemo(() => layoutState.tablet ? (videoSize[1] + controllerHeight > layoutState.height) : false, [videoSize, controllerHeight, layoutState]);
  const videoAreaMarginTop = React.useMemo(() => {
    if (layoutState.mobile) {
      if (layoutState.portrait) {
        if (mobileFullScreen) {
          return ((layoutState.height - videoSize[1]) * 0.5) - (topBarHeight ?? 0);
        }
        return notEnoughtHeight ? (videoSize[1] + controllerHeight - layoutState.height) : undefined;
      }
      else {
        return notEnoughtHeight ? (videoSize[1] + controllerHeight - layoutState.height) : undefined;
      }
    }
    if (layoutState.tablet) {
      return (layoutState.height - videoSize[1]) * 0.5;
    }
    return notEnoughtHeight ? (videoSize[1] + controllerHeight - layoutState.height) : undefined
  }, [controllerHeight, layoutState, mobileFullScreen, notEnoughtHeight, topBarHeight, videoSize]);

  const videoMarginTop = React.useMemo(() => {
    if (layoutState.mobile) {
      if (layoutState.portrait) {
        if (mobileFullScreen) {
          return (videoAreaMarginTop ?? 0) + (topBarHeight ?? 0);
        }
        return topBarHeight;
      }
    }
    if (layoutState.tablet) {
      return Math.floor((layoutState.height - layoutState.videoHeight) * 0.5);
    }
    return !(layoutState.mobile && layoutState.portrait) && notEnoughtHeight ? 0 : videoAreaMarginTop;
  }, [layoutState, mobileFullScreen, topBarHeight, notEnoughtHeight, videoAreaMarginTop]);
  const videoAreaHeight = React.useMemo(() => {
    if (layoutState.tablet) {
      if (props.showModels) {
        return videoSize[1] - controllerHeight + (layoutState.height - videoSize[1]) * 0.5;
      }
      return videoSize[1] - controllerHeight;
    }
    return notEnoughtHeight ? layoutState.height - controllerHeight - (videoAreaMarginTop ?? 0) : (layoutState.tablet ? layoutState.height - controllerHeight - (videoAreaMarginTop ?? 0) :videoSize[1]);
  }, [notEnoughtHeight, layoutState, controllerHeight, props, videoAreaMarginTop, videoSize])
  // ---------- CALCULATE LAYOUT END ----------

  const [autoPlay, setAutoPlay] = React.useState(false);
  const [videoEnded, setVideoEnded] = React.useState(false);
  const [videoPlay, setVideoPlay] = React.useState(undefined);
  const [soundOn, setSoundOn] = React.useState(true);
  const [time, setTime] = React.useState([0, 0]);
  const [simpleController, setSimpleController] = React.useState(false);
  const [videoNotPlayed, setVideoNotPlayed] = React.useState(true);
  const pauseWhenPopup = React.useRef(false);
  const timerForSimpleController = React.useRef(null);

  const handleTimerForSimpleController = React.useCallback((active: boolean) => {
    function changeToSimpleController() {
      setSimpleController(true);
    }

    if (timerForSimpleController.current != null) {
      clearTimeout(timerForSimpleController.current);
    }

    if (active && videoRef.current && !videoRef.current.paused) {
      timerForSimpleController.current = setTimeout(changeToSimpleController, 2000);
    }
  }, []);

  const handleVideoPlay = React.useCallback((play: boolean) => {
    setVideoPlay(play);
    if (play) {
      videoRef.current?.play();
    }
    else {
      videoRef.current?.pause();
    }
  }, []);

  const handleSoundOn = React.useCallback((on: boolean) => {
    setSoundOn(on);
    handleTimerForSimpleController(true);
  }, [handleTimerForSimpleController]);

  const handleVideoSeek = React.useCallback((timeRatio: number) => {
    const video = videoRef.current;
    if (video) {
      if (!isNaN(video.duration)) {
        video.currentTime = video.duration * timeRatio;
      }
    }
    handleTimerForSimpleController(true);
  },[handleTimerForSimpleController]);

  const handleFullscreenOn = React.useCallback(() => {
    const video = videoRef.current;
      if (video) {
        //$FlowIssue
        if (video.webkitEnterFullScreen !== undefined) {
          //$FlowIssue
          video.webkitEnterFullScreen();
        }
        else {
          video.requestFullscreen?.();
        }
        //setSimpleController(true);
        handleTimerForSimpleController(false);
      }
  }, [handleTimerForSimpleController]);

  const handleFullscreenOff = React.useCallback(() => {

  }, []);

  const handleHideModels = React.useCallback(() => {
    if(!isMobile()) {
      props.onHideModels?.();
    }
    else {
      handleFullscreenOn();
    }
  }, [props, handleFullscreenOn]);

  const handleShowModels = React.useCallback((e: ?Event) => {
    if(!isMobile()) {
      props.onShowModels?.();
    }
    e?.stopPropagation();
  }, [props]);

  const handleVideoControllerMode = React.useCallback((flag: boolean) => {
    //console.log('simple-', flag);
    setSimpleController(flag);
    if (!flag) {
      handleTimerForSimpleController(true);
      setAutoPlay(false);
    }
    if (videoPlay !== undefined) {
      setVideoNotPlayed(false);
    }
  }, [handleTimerForSimpleController, videoPlay]);

  React.useEffect(() => {
    if (layoutState.tablet) {
      if (props.showModels === true) {
        if (videoRef.current && !videoRef.current.paused) {
          videoRef.current.pause();
          pauseWhenPopup.current = true;
        }
      }
      else if (props.showModels === false) {
        if (pauseWhenPopup.current) {
          videoRef.current?.play();
          pauseWhenPopup.current = false;
        }
      }
    }
  }, [props.showModels, layoutState.tablet]);

  React.useEffect(() => {
    if (videoEnded && videoRef.current?.ended === true) {
      if (layoutState.tablet && props.showModels !== true) {
        props.onShowModels?.();
      }
    }
  }, [videoEnded, props, layoutState.tablet]);

  React.useEffect(() => {
    function handleTimeUpdate(event: Event) {
      setTime([Math.round((videoRef.current?.currentTime ?? 0) * 100) * 0.01, !isNaN(videoRef.current?.duration) ? (videoRef.current?.duration ?? 0) : 0]);
    }
    function handlePlay(event: Event) {
      setVideoPlay(true);
      setVideoEnded(false);
      handleTimerForSimpleController(true);
    }
    function handlePause(event: Event) {
      setVideoPlay(false);
      handleTimerForSimpleController(false);
      setVideoNotPlayed(false);
    }
    function handleEnded(event: Event) {
      setVideoEnded(true);
      handleTimerForSimpleController(false);
      setVideoNotPlayed(false);
    }

    videoRef.current?.addEventListener('timeupdate', handleTimeUpdate);
    videoRef.current?.addEventListener('play', handlePlay);
    videoRef.current?.addEventListener('pause', handlePause);
    videoRef.current?.addEventListener('ended', handleEnded);
    const video = videoRef.current;
    return () => {
      video?.removeEventListener('timeupdate', handleTimeUpdate);
      video?.removeEventListener('play', handlePlay);
      video?.removeEventListener('pause', handlePause);
      video?.removeEventListener('ended', handleEnded);
    }
  }, [videoRef, handleTimerForSimpleController]);

  //console.log(notEnoughtHeight, videoSize, videoMarginTop, videoAreaMarginTop, videoAreaTop);
  //console.log(videoPlay, autoPlay, videoEnded, props.showModels);
  
  return (
    <Wrapper mobileFullScreen={mobileFullScreen}>
      <CanvasOverrideStyle modelName={props.contents?.text.name} videoWidth={videoSize[0]} videoHeight={videoSize[1]} videoTop={videoAreaTop} mobileFullScreen={mobileFullScreen} />
      <InlineVideoWrapper videoWidth={videoSize[0]} videoHeight={videoSize[1]} videoTop={videoMarginTop} mobileFullScreen={mobileFullScreen} onClick={() => handleVideoControllerMode(!simpleController)}>
        <ModelVideo key={(session.modelCode ?? "") + session.colorCode} ref={videoRef} playsInline={true} muted={!soundOn} autoPlay={autoPlay} preload="auto" disablePictureInPicture={true} poster={posterFile}>
          {videoFile && <source src={videoFile} type="video/mp4"></source>}
        </ModelVideo>
      </InlineVideoWrapper>
      <IsTabletAgent>
        <DimGradient bgColor={props.contents?.ui.player_bg_color} showModels={props.showModels} simpleController={simpleController} />
      </IsTabletAgent>
      {visibleTopBar && <TopBar show={props.showModels === undefined ? undefined : (mobileFullScreen ? false : true)}><img src={Logo} alt="logo"/></TopBar>}
      <VideoArea ref={videoAreaRef} videoWidth={videoSize[0]} videoHeight={videoAreaHeight} videoMarginTop={videoAreaMarginTop} bgColor={props.contents?.ui.player_bg_color} simpleController={simpleController} showModels={props.showModels}>
        {!(UseMobileNativePlayer && isMobile()) &&
          <ButtonPlayVideo onClick={() => handleVideoPlay(!videoPlay)} videoEnded={videoEnded} showPlayButton={simpleController ? (videoEnded && !props.showModels ? true : false) : (videoPlay ? false : true)} showPauseButton={simpleController ? false : (videoPlay && !autoPlay && !videoNotPlayed ? true : false)} showModels={props.showModels}>
            <ReplayVideoIcon />
            <PlayVideoIcon />
            <PauseVideoIcon />
            
          </ButtonPlayVideo>
        }
      </VideoArea>
      <ControllerArea videoWidth={videoSize[0]} videoHeight={videoSize[1]} controllerHeight={controllerAreaHeight} mobileFullScreen={mobileFullScreen} showModels={props.showModels} onClick={() => {handleVideoControllerMode(props.showModels ? simpleController : !simpleController)}}>
        <VideoController contents={props.contents} videoPlay={videoPlay} soundOn={soundOn} mobileFullScreen={mobileFullScreen} showModels={props.showModels} onShowModels={handleShowModels} onHideModels={handleHideModels} onFullscreenOn={handleFullscreenOn} onFullscreenOff={handleFullscreenOff} onVideoPlay={handleVideoPlay} onSoundOn={handleSoundOn} onVideoSeek={handleVideoSeek} time={time} videoEnded={videoEnded} simpleController={simpleController} onClickBuild={props.onShowBlackout} />
      </ControllerArea>
    </Wrapper>
  );
}

const transitionDuration = 0.4;

const openTopBarAnimation = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0px); }
`

const closeTopBarAnimation = keyframes`
  0% { transform: translateY(0px); }
  100% { transform: translateY(-100%); }
`

const Wrapper = styled.div`
  width: 100%;
`;

const CanvasOverrideStyle = createGlobalStyle`
  #root-canvas {
    ${'' /* background-image: ${(props) =>
    `url('https://picsum.photos/seed/${props.modelName.replace(
      /\s/g,
      ''
    )}/1600/900')`};
    background-size: cover; */}

    width: ${props => props.theme.layout.width}px;
    height: ${props => props.theme.layout.tablet ? props.theme.layout.height : props.videoHeight}px;
    top: ${props => props.videoTop ? `${props.videoTop}px` : undefined};
    background-color: #000000;
    transition: ${UseMobileNativePlayer ? undefined : `height, top ${transitionDuration}s`};
  }
`

const InlineVideoWrapper = styled.div`
  position: absolute;

  width: ${props => props.videoWidth}px;
  height: ${props => props.videoHeight}px;
  left: ${props => (props.theme.layout.width - props.videoWidth) * 0.5}px;
  top: ${props => props.videoTop !== undefined ? `${props.videoTop}px` : undefined};

  transition: ${UseMobileNativePlayer ? undefined : `height, top ${transitionDuration}s`};
`

const TopBar = styled.div`
  background-color: ${ThemeBlackColor};

  padding: 20px 20px 19px 20px;

  animation-name: ${props => (props.show === undefined) ? 'undefined' : (props.show ? openTopBarAnimation : closeTopBarAnimation)};
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
`

//padding-top: ${props => props.theme.layout.tablet ? `${(props.theme.layout.height - props.videoHeight) * 0.5}px` : undefined};
const VideoArea = styled.div`
  width: ${props => props.videoWidth}px;
  height: ${props => props.videoHeight}px;

  margin-left: ${props => (props.theme.layout.mobile || props.theme.layout.tablet) ? `${(props.theme.layout.width - props.videoWidth) * 0.5}px` : undefined};
  margin-top: ${props => props.videoMarginTop ? `${props.videoMarginTop}px` : undefined};
  padding-top: ${props => props.theme.layout.tablet ? `${props.theme.layout.controllerHeight - 74}px` : undefined};
  box-sizing: ${props => props.theme.layout.tablet ? 'border-box' : undefined};

  display: flex;
  align-items: center;
  justify-content: center; 

  transition: margin-top, height ${transitionDuration}s;
`;

const DimGradient = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);;
  position: absolute;
  left: ${props => (props.theme.layout.width - props.theme.layout.videoWidth) * 0.5}px;
  top: ${props => (props.theme.layout.height - props.theme.layout.videoHeight) * 0.5}px;
  width: ${props => props.theme.layout.videoWidth}px;
  height: ${props => props.theme.layout.videoHeight}px;

  opacity: ${props => props.simpleController || props.showModels ? 0 : 1};
  transition: opacity 0.1s linear ${props => props.showModels ? '0.25s' : undefined};
`

const ControllerArea = styled.div`
  width: ${props => props.theme.layout.width > props.theme.layout.videoWidth ? `${props.theme.layout.videoWidth}px` : '100%'};
  height: ${props => props.controllerHeight}px;
  margin-left: ${props => props.theme.layout.width > props.theme.layout.videoWidth ? `${(props.theme.layout.width - props.theme.layout.videoWidth) * 0.5}px` : undefined};
  margin-bottom : ${props => props.theme.layout.tablet && !props.showModels ? `${(props.theme.layout.height - props.theme.layout.videoHeight) * 0.5}px` : undefined};

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  z-index: 5;
  overflow-y: hidden;
  transition: height, margin-bottom ${transitionDuration}s;

  pointer-events: auto;
`

const ButtonPlayVideo = styled.button`
  width: ${(props) => Math.round((props.theme.layout.mobile ? 62 : 100) * props.theme.layout.controllerHeightRatio)}px;
  height: ${(props) =>  Math.round((props.theme.layout.mobile ? 62 : 100) * props.theme.layout.controllerHeightRatio)}px;
  visibility: ${props => (props.showPlayButton || props.showPauseButton) ? 'visible' : 'hidden'};

  z-index: ${props => props.showModels ? undefined : 1};
  opacity: ${props => props.showModels ? 0 : 1};
  transition: ${props => props.showModels ? undefined : `opacity 0.25s ease-in 0.25s`};
  animation-fill-mode: both;

  svg {
    width: ${(props) => Math.round((props.theme.layout.mobile ? 62 : 100) * props.theme.layout.controllerHeightRatio)}px;
    height: ${(props) =>  Math.round((props.theme.layout.mobile ? 62 : 100) * props.theme.layout.controllerHeightRatio)}px;

    position: absolute;
    transition: opacity 0.25s ease-in 0.25s;
  }

  > :nth-child(1) {
    opacity: ${props => props.showPlayButton && props.videoEnded ? 1 : 0};
  }

  > :nth-child(2) {
    opacity: ${props => props.showPlayButton && !props.videoEnded ? 1 : 0};
  }

  > :nth-child(3) {
    opacity: ${props => props.showPauseButton ? 1 : 0};
  }

  > :last-child {
    position: relative;
  }
`

const ModelVideo = styled.video`
  width: 100%;
  height: 100%;
  pointer-events: auto;
`
