// @flow
import * as React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {useLayoutState} from 'components/Layout';
import {Defaults} from 'components/Constants';
import {ScrollToTop} from 'utils/hookUtils';
import Main from './Main';
import {DevUrlList} from './NotFound';

export default function PageRouter(): React.Element<any> {
  const layoutState = useLayoutState();
  const theme = React.useMemo(() => {
    return {layout: layoutState, defaults: Defaults};
  }, [layoutState]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<MainRoutes />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

const SHOW_DEV_URLS = process.env.NODE_ENV !== "production" || window.location.hostname.includes("s3-website");

function MainRoutes() {
  return (
    <Routes>
      {SHOW_DEV_URLS && <Route path="/" element={<DevUrlList />} />}
      {SHOW_DEV_URLS && <Route path="/:countryCode" element={<DevUrlList />} />}
      <Route path="/:countryCode/*" element={<Main />} />
      <Route path="*" element={<Main />} />
    </Routes>
  );
}