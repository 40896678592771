// @flow
import * as React from 'react';
import './styles/index.css';
import {Layout} from 'components/Layout';
import Session from 'components/Session';
import PageRouter from 'pages/PageRouter';

function App(): React.Element<any> {
  if (process.env.REACT_APP_VERSION) {
    window.appVersion = `${process.env.REACT_APP_VERSION ?? 0}.${process.env.REACT_APP_BUILD_VERSION ?? 0}`;
  }
  
  return (
    <Layout>
      <Session>
        <PageRouter />
      </Session>
    </Layout>
  );
}

export default App;
