// @flow
import * as React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import {useLayoutState} from 'components/Layout';
import {IsMobileAgent, IsNotMobileAgent, isMobile} from 'components/Responsive';
import {UseMobileNativePlayer, ThemeWhiteColor, ThemeBlackColor} from 'components/Constants';
import { PlayIcon, ReplayIcon, PauseIcon, FullScreenIcon, NormalScreenIcon, FullScreenIconT, NormalScreenIconT, SoundOnIcon, SoundOffIcon, SoundOnIconM, SoundOffIconM, RightArrowLarge } from 'components/Icons';
import { useSessionState } from 'components/Session';
import {UnderlineButton} from 'components/BuildButton';

type Props = {
  contents?: Object,
  videoPlay?: boolean,
  soundOn: boolean,
  mobileFullScreen?: boolean,
  showModels?: boolean,
  onShowModels?: (Event) => void,
  onHideModels?: () => void,
  onFullscreenOn?: () => void,
  onFullscreenOff?: () => void,
  onVideoPlay?: (boolean) => void,
  onSoundOn?: (boolean) => void,
  onVideoSeek?: (number) => void,
  time: Array<number>,
  videoEnded: boolean,
  simpleController: boolean,
  onClickBuild?: () => void,
};

export function VideoController(props: Props): React.Element<any> {
  const layoutState = useLayoutState();
  const session = useSessionState();
  const bgColor: ?string = props.contents?.ui.player_bg_color
  const textColor: ?string = (layoutState.tablet && !props.showModels) ? ThemeWhiteColor : props.contents?.ui.player_text_color
  const slideMax = 10000;
  const colorCode = React.useMemo(() => session.colorCode?.toLowerCase(), [session]);
  const timeString = React.useMemo(() => {
    return [timeToString(props.time[0]), timeToString(props.time[1])]    
  }, [props.time]);
  const controllerPadding = React.useMemo(() => {
    return layoutState.mobile ? 20 : 30;
  }, [layoutState]);
  const controllerPaddingH = React.useMemo(() => {
    return layoutState.mobile ? 20 : 40;
  }, [layoutState]);
  const controllerTopLineHeight = React.useMemo(() => {
    return Math.round((layoutState.mobile ? 24 : 36) * layoutState.controllerHeightRatio);
  }, [layoutState])
  const controllerTopTranslate = React.useMemo(() => {
    if (layoutState.tablet && props.showModels) {
      return Math.floor((layoutState.controllerHeight - (controllerPadding * 2) - controllerTopLineHeight) * 0.5);
    }
    if (!props.simpleController) {
      if (props.videoPlay === undefined) {
        if (layoutState.tablet) {
          return 48;
        }
        else {
          return Math.floor((layoutState.controllerHeight - (controllerPadding * 2) - controllerTopLineHeight) * 0.5);
        }
      }
      return undefined;
    }
    if (layoutState.tablet) {
      return layoutState.controllerHeight - controllerPadding;
    }
    return Math.floor((layoutState.controllerHeight - (controllerPadding * 2) - controllerTopLineHeight) * 0.5);
  }, [props, layoutState, controllerPadding, controllerTopLineHeight]);
  const controllerBottomTranslate = React.useMemo(() => {
    if (layoutState.tablet && props.showModels) {
      return layoutState.controllerHeight - controllerPadding;
    }
    if (!props.simpleController) {
      if (props.videoPlay === undefined) {
        return layoutState.controllerHeight - controllerPadding;
      }
      return undefined;
    }
    if (layoutState.tablet) {
      return layoutState.controllerHeight - controllerPadding;
    }
    return Math.floor(layoutState.controllerHeight - controllerPadding - controllerTopLineHeight);
  }, [props, layoutState, controllerPadding, controllerTopLineHeight])

  const handleChangeStep = React.useCallback((value) => {
    props.onVideoSeek?.(value / slideMax);
  }, [props]);

  const handleVideoReplay = React.useCallback(() => {
    props.onHideModels?.();
    if (props.videoEnded) {
      props.onVideoPlay?.(true);
    }
  }, [props]);

  const handleMobileFullscreen = React.useCallback((e) => {
    e.stopPropagation();
    if (props.mobileFullScreen === false) {
      props.onHideModels?.();
    } 
    else {
      props.onShowModels?.(e);
    }
  }, [props]);

  const handleFullscreen = React.useCallback((e) => {
    e.stopPropagation();
    if (props.mobileFullScreen === false) { 
      props.onFullscreenOn?.();
    }
    else {
      props.onFullscreenOff?.();
    }
  }, [props]);

  const openInNewTab = React.useCallback((url: string): void => {
    // if (props.buttonDisabled === true) {
    //   return;
    // }
    const newWindow = window.open(url, '_self', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
    props.onClickBuild?.();
  }, [props]);

  const existsBuild = React.useCallback((model: string, colorCode?: string): boolean => {
    return colorCode !== undefined && session.settings.models[model].links?.[colorCode];
  }, [session.settings]);

  //console.log(props.time, props.time[1] > 0 ? (props.time[0] / props.time[1] * slideMax) : 0);
  //console.log((layoutState.controllerHeight - (controllerPadding * 2) - controllerTopLineHeight) * 0.5, layoutState.controllerHeight, controllerPadding, controllerTopLineHeight);
  return (
    <Wrapper mobileFullScreen={props.mobileFullScreen} bgColor={bgColor} simpleController={props.simpleController} showModels={props.showModels}>
      <Container textColor={textColor} controllerPadding={controllerPadding} controllerPaddingH={controllerPaddingH}>
        <ControllerTop simpleDisplay={props.simpleController} controllerTopTranslate={controllerTopTranslate}>
          <ControllerTitle controllerTopLineHeight={controllerTopLineHeight} showModels={props.showModels}>
            <div>
            <ButtonPlay textColor={textColor} pause={!props.videoPlay} onClick={(e) => {props.onVideoPlay?.(!props.videoPlay); e.stopPropagation()}} controllerTopLineHeight={controllerTopLineHeight}>
            <span>{props.contents?.text.name}</span>
            {!(UseMobileNativePlayer && isMobile()) && false &&
              <div>
                {!props.videoEnded && <PlayIcon />}
                {props.videoEnded && <ReplayIcon />}
                <PauseIcon />
              </div>
            }
            </ButtonPlay>
            <ModelTitle controllerTopLineHeight={controllerTopLineHeight}>
              {!session.modelCode && props.contents?.text.choose_your_model}
              {session.modelCode && <ModelBuildButton controllerTopLineHeight={controllerTopLineHeight} disabled={!existsBuild(session.modelCode ?? "", colorCode)} textColor={textColor} onAnimationEnd={session.modelCode && colorCode && session.settings.models[session.modelCode].links?.[colorCode] ? () => openInNewTab(session.settings.models[session.modelCode].links[colorCode]) : undefined}><span>{existsBuild(session.modelCode ?? "", colorCode) ? props.contents?.text.build_button : props.contents?.text.coming_soon_button}</span> <RightArrowLarge /></ModelBuildButton>}
            </ModelTitle>
            </div>
          </ControllerTitle>
          <IsNotMobileAgent>
            <ButtonModelsWrapper>
              <ButtonModelsContainer showModels={props.showModels}>
                <ButtonModels textColor={textColor} bgColor={textColor?.toLowerCase() === ThemeWhiteColor.toLowerCase() ? ThemeBlackColor : ThemeWhiteColor} toTop={props.showModels !== true} onClick={props.showModels === true ? handleVideoReplay :props.onShowModels}>{props.showModels === true ? (props.videoEnded ? props.contents?.text.player_replay : props.contents?.text.player_close):props.contents?.text.player_models}</ButtonModels>
              </ButtonModelsContainer>
            </ButtonModelsWrapper>
          </IsNotMobileAgent>
          {!UseMobileNativePlayer &&
          <IsMobileAgent>
            <ButtonFullscreen textColor={textColor} mobileFullScreen={props.mobileFullScreen} onClick={handleMobileFullscreen} controllerTopLineHeight={controllerTopLineHeight}><FullScreenIcon /><NormalScreenIcon/></ButtonFullscreen>
          </IsMobileAgent>
          }
        </ControllerTop>
        {!(UseMobileNativePlayer && isMobile()) &&
        <ControllerSlider simpleDisplay={props.simpleController} controllerBottomTranslate={controllerBottomTranslate}>
          <div onClick={(e) => e.stopPropagation()}>
          <StyledSlider
              max={slideMax}
              value={props.time[1] > 0 ? (props.time[0] / props.time[1] * slideMax) : 0}
              //marks={[10, 20, 100]}
              //step={100}
              renderTrack={Track}
              renderThumb={(props, state) => <div {...props}></div>}
              onChange={val => handleChangeStep(val)}
              onSliderClick={val => handleChangeStep(val)}
              //onAfterChange={val => handleChangeGauge(val)}
              textColor={textColor}
              
          />
          </div>
          <ControllerBottom>
            <ControllerTime>
              {timeString[0]} <span>/ {timeString[1]}</span>
            </ControllerTime>
            <IsMobileAgent>
              <ButtonSound textColor={textColor} soundOn={props.soundOn} onClick={(e) => {props.onSoundOn?.(!props.soundOn); e.stopPropagation();}}>
                <SoundOnIconM />
                <SoundOffIconM />
              </ButtonSound>
            </IsMobileAgent>
            <IsNotMobileAgent>
              <>
                <ButtonSoundT textColor={textColor} soundOn={props.soundOn} onClick={(e) => {props.onSoundOn?.(!props.soundOn); e.stopPropagation();}}>
                  <SoundOnIcon />
                  <SoundOffIcon />
                </ButtonSoundT>
                <ButtonFullscreenT textColor={textColor} mobileFullScreen={props.mobileFullScreen} onClick={handleFullscreen} controllerTopLineHeight={controllerTopLineHeight}><FullScreenIconT /><NormalScreenIconT/></ButtonFullscreenT>
              </>
            </IsNotMobileAgent>
          </ControllerBottom>
        </ControllerSlider>
        }
      </Container>
    </Wrapper>
  );
}

function timeToString(time: number): string {
  const hours = Math.floor(time / (60 * 60));
  const minutes = Math.floor(time / 60 % 60);
  const seconds = Math.floor(time % 60);
  return (hours > 0 ? hours.toString().padStart(2, '0') + ":" : "") + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
}

// background-color: ${props => props.theme.layout.tablet && !props.showModels ? `${props.bgColor}00` : props.bgColor};
//   transition: ${props => props.showModels ? undefined : 'background-color 0.25s linear 0.25s'};
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: ${props => !props.theme.layout.mobile && !props.theme.layout.tablet ? props.bgColor : undefined};
`;

const Container = styled.div`
  width: 100%;
  padding: ${(props) => props.controllerPadding}px ${props => props.controllerPaddingH}px;
  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.theme.layout.mobile && props.theme.layout.portrait ? `1px solid ${props.textColor}12` : undefined};

  color: ${props => props.textColor};

  button {
    color: ${props => props.textColor};
  }
`

const SimpleDisplayDuration = 0.5;

const ControllerTop = styled.div`
  display: flex;
  align-items: center;

  transform: ${props => props.controllerTopTranslate ? `translateY(${props.controllerTopTranslate}px)` : undefined};
  transition: transform ${SimpleDisplayDuration}s;
`;

const ControllerTitle = styled.div`
  height: ${(props) => props.controllerTopLineHeight}px;
  flex: 1;
  overflow-y: hidden;
  > div {
    transform: ${props => props.showModels === true ? `translateY(${-props.controllerTopLineHeight}px)` : 'translateY(0px)'};    
    transition: transform 0.25s;
  }
`;

const ButtonPlay = styled.button`
  display: flex;

  > span {
    font-weight: 300;
    font-family: genesis-head;
    font-size: ${(props) => Math.round((props.theme.layout.mobile ? 24 : 36) * props.theme.layout.controllerHeightRatio)}px;
    line-height: ${(props) => props.controllerTopLineHeight}px;
  }

  > div {
    width: ${(props) => (props.theme.layout.mobile ? 18 : 32) * props.theme.layout.controllerHeightRatio}px;
    height: ${(props) => (props.theme.layout.mobile ? 18 : 32) * props.theme.layout.controllerHeightRatio}px;
    margin-left: 10px;
    margin-top: ${(props) => (props.theme.layout.mobile ? 3 : 5) * props.theme.layout.controllerHeightRatio}px;;
    position: relative;

    > svg {
      position: absolute;
      left: 0;
      top: 0;
      transition: opacity 0.25s;
      width: ${(props) => (props.theme.layout.mobile ? 18 : 32) * props.theme.layout.controllerHeightRatio}px;
      height: ${(props) => (props.theme.layout.mobile ? 18 : 32) * props.theme.layout.controllerHeightRatio}px;
      
      > *[stroke] {
        stroke: ${(props) => props.textColor};
      }
      > *[fill] {
        fill: ${(props) => props.textColor};
      }
    }

    > :nth-child(1) {
      opacity: ${props => props.pause ? 1 : 0};
    }

    > :nth-child(2) {
      opacity: ${props => props.pause ? 0 : 1};
    }

    > :nth-child(3) {
      opacity: 0;
    }
  }
`;

const ModelTitle = styled.div`
  font-weight: 300;
  font-family: genesis-head;
  font-size: ${(props) => (props.theme.layout.mobile ? 24 : 36) * props.theme.layout.controllerHeightRatio}px;
  line-height: ${(props) => props.controllerTopLineHeight}px;
`

const ModelBuildButton = styled(UnderlineButton)`
  line-height: ${(props) => props.controllerTopLineHeight}px;
  display: flex;
  align-items: center;

  > span {
    font-weight: 300;
    font-family: genesis-head;
    font-size: ${(props) => (props.theme.layout.mobile ? 24 : 36) * props.theme.layout.controllerHeightRatio}px;
  }

  > svg {
    width: 15px;
    height: 26px;
    padding-left: 12px;

    > *[stroke] {
      stroke: ${props => props.textColor};
    }
    > *[fill] {
      fill: ${props => props.textColor};
    }
  }
`

const ButtonModelsWrapper = styled.div`
  float: right;
  height: ${props => Math.round(46 * props.theme.layout.controllerHeightRatio)}px;
  overflow-y: hidden;
`

const ButtonModelsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonModels = styled.button`
  width: ${props => Math.round(160 * props.theme.layout.controllerHeightRatio)}px;
  height: ${props => Math.round(40 * props.theme.layout.controllerHeightRatio)}px;
  margin: ${props => Math.round(3 * props.theme.layout.controllerHeightRatio)}px 0px;
  font-weight: 300;
  font-size: ${props => Math.round(20 * props.theme.layout.controllerHeightRatio)}px;
  font-family: genesis-head;

  border: 1px solid ${props => props.textColor}33;
`;

const ButtonFullscreen = styled.button`
  width: 16px;
  height: ${props => props.controllerTopLineHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > :first-child {
    position: absolute;
    opacity: ${props => props.mobileFullScreen === false ? 1 : 0};
  }
  > :last-child {
    opacity: ${props => props.mobileFullScreen === false ? 0 : 1};
  }

  > svg {
    transition: opacity 0.25s;
    > *[stroke] {
      stroke: ${(props) => props.textColor};
    }
    > *[fill] {
      fill: ${(props) => props.textColor};
    }
  }

  position: relative;
  &::after {
    content: '';
    position: absolute;
     top: -50%;
    left: -50%;
     width: 200%;
    height: 200%;
  }
`

const ButtonFullscreenT = styled.button`
  height: ${props => Math.round(22 * props.theme.layout.controllerHeightRatio)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => Math.round(20 * props.theme.layout.controllerHeightRatio)}px;
  
  > :first-child {
    position: absolute;
    opacity: ${props => props.mobileFullScreen === false ? 1 : 0};
  }
  > :last-child {
    opacity: ${props => props.mobileFullScreen === false ? 0 : 1};
  }

  > svg {
    height: ${props => Math.round(22 * props.theme.layout.controllerHeightRatio)}px;
    transition: opacity 0.25s;
    > *[stroke] {
      stroke: ${(props) => props.textColor};
    }
    > *[fill] {
      fill: ${(props) => props.textColor};
    }
  }

  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -45%;
    left: -45%;
    width: 180%;
    height: 180%;
  }
`

const ControllerSlider = styled.div`
  transform: ${props => props.controllerBottomTranslate ? `translateY(${props.controllerBottomTranslate}px)` : undefined};
  transition: transform ${SimpleDisplayDuration}s;
`

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: ${(props) => (props.theme.layout.mobile ? 24 : 34) * props.theme.layout.controllerHeightRatio}px;
  pointer-events: auto;
  > * {
    pointer-events: auto;
  }

  .track-0 {
    background-color: ${props => props.textColor};
    opacity: ${props => props.value <= 0 ? 0 : 1};

    height: ${(props) => (props.theme.layout.mobile ? 4 : 4)}px;
    top: ${(props) => (props.theme.layout.mobile ? 10 : 14) * props.theme.layout.controllerHeightRatio}px;
  }
  .track-1 {
    background-color: ${props => props.textColor};
    opacity: 0.15;

    height: ${(props) => (props.theme.layout.mobile ? 4 : 4)}px;
    top: ${(props) => (props.theme.layout.mobile ? 10 : 14) * props.theme.layout.controllerHeightRatio}px;
  }

  .thumb-0 {
    width: ${(props) => (props.theme.layout.mobile ? 24 : 34) * props.theme.layout.controllerHeightRatio}px;
    height: ${(props) => (props.theme.layout.mobile ? 24 : 34) * props.theme.layout.controllerHeightRatio}px;
    outline: 0px;
  }
`;

const ControllerBottom = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ControllerTime = styled.div`
  font-family: genesis-text-regular;
  font-size: ${(props) => Math.round((props.theme.layout.mobile ? 14 : 16) * props.theme.layout.controllerHeightRatio)}px;
  line-height: ${(props) => Math.round((props.theme.layout.mobile ? 18 : 22) * props.theme.layout.controllerHeightRatio)}px;
  flex: 1;
  margin-top: ${props => props.theme.layout.mobile ? 2 : 0}px;

  > span {
    opacity: 0.4;
  }
`;

const ButtonSoundT = styled.button`
  float: right;
  width: ${props => Math.round(22 * props.theme.layout.controllerHeightRatio)}px;
  height: ${props => Math.round(22 * props.theme.layout.controllerHeightRatio)}px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  > svg {
    height: ${props => Math.round(22 * props.theme.layout.controllerHeightRatio)}px;
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 0.25s;

    > *[stroke] {
      stroke: ${(props) => props.textColor};
    }
    > *[fill] {
      fill: ${(props) => props.textColor};
    }
  }

  >:first-child {
    opacity: ${props => props.soundOn ? 1 : 0}
  }

  >:last-child {
    opacity: ${props => props.soundOn ? 0 : 1}
  }

  position: relative;
  &::after {
    content: '';
    position: absolute;
     top: -45%;
    left: -45%;
     width: 180%;
    height: 180%;
  }
`;

const ButtonSound = styled.button`
  float: right;
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  > svg {
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 0.25s;

    > *[stroke] {
      stroke: ${(props) => props.textColor};
    }
    > *[fill] {
      fill: ${(props) => props.textColor};
    }
  }

  >:first-child {
    opacity: ${props => props.soundOn ? 1 : 0}
  }

  >:last-child {
    opacity: ${props => props.soundOn ? 0 : 1}
  }

  position: relative;
  &::after {
    content: '';
    position: absolute;
     top: -50%;
    left: -50%;
     width: 200%;
    height: 200%;
  }
`;
