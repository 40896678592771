// @flow
import * as React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import { isMobileOnly as isMobile, isTablet, isMobileSafari, isChrome, isIOS } from 'react-device-detect';
import { ControllerHeight } from 'components/Constants';
import {getWindowSize, useWindowSize} from 'utils/windowUtils';
const LayoutContext: Object = React.createContext(null);

type LayoutState = {
  width: number,
  height: number,
  scale: number,
  mobile: boolean,
  tablet: boolean,
  portrait: boolean,
  videoWidth: number,
  videoHeight: number,
  controllerHeight: number,
  controllerHeightRatio: number,
};

type Props = {
  children?: React.Node,
};

export function Layout(props: Props): React.Element<any> {
  const layoutRef = React.useRef({});
  const size = useWindowSize();

  const mobile = isMobile;
  const landscape = !(mobile || isTablet) || (size[0] >= size[1]);
  const scale = 1;
  const timer = React.useRef(null);
  const videoSize = CalcVideoSize(mobile, isTablet, size[0], size[1]);
  layoutRef.current = {
    width: size[0],
    height: size[1],
    scale: scale,
    mobile: mobile,
    tablet: !mobile,
    portrait: !landscape,
    videoWidth: videoSize[0],
    videoHeight: videoSize[1],
    controllerHeight: isMobile ? ControllerHeight.mobile : ControllerHeight.desktop,
    controllerHeightRatio: isTablet ? Math.min(1, size[0] / 1366) : 1
  }

  //console.log(layoutRef.current);

  React.useEffect(() => {
    timer.current = setTimeout(() => {
      const windowSize = getWindowSize();
      if (windowSize[0] !== layoutRef.current.width || windowSize[1] !== layoutRef.current.height) {
        //console.log(windowSize);
        window.dispatchEvent(new Event('resize'));
      }
      timer.current = null;
    }, 1000);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    }
  }, []);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    window.debugLayout = layoutRef.current;
  }

  return (
    <LayoutContext.Provider value={layoutRef.current}>
      {isMobileSafari && <RootStyle datawidth={size[0]} dataheight={size[1]} />}
      {props.children}
    </LayoutContext.Provider>);
}

const RootStyle = createGlobalStyle`
  ${'' /* #root {
    width: calc(${props => props.datawidth}px - env(safe-area-inset-right) - env(safe-area-inset-left));
    height: calc(${props => props.dataheight}px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }

  #root-canvas {
    width: ${props => props.datawidth}px;
    height: ${props => props.dataheight}px;
  } */}

  .modal-overlay {
    width: calc(${props => props.datawidth}px - env(safe-area-inset-right) - env(safe-area-inset-left));
    height: calc(${props => props.dataheight}px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }
`;

export function useLayoutState(): LayoutState {
  const state = React.useContext(LayoutContext);
  if (!state) {
    throw new Error('Cannot find Provider');
  }
  return state;
}

export function useLayoutScale(baseScale?: number, minScale?: number, maxScale?: number): ?number {
  const clampScale = React.useCallback(
    (baseScale?: number, minScale?: number, maxScale?: number) => {
      let result = baseScale;
      if (baseScale) {
        if (minScale) {
          result = Math.max(minScale, baseScale);
        }
        if (maxScale) {
          result = Math.min(maxScale, baseScale);
        }
      }
      return result;
    },
    []
  );

  const [scale, setScale] = React.useState(clampScale(baseScale, minScale, maxScale));

  React.useEffect(() => {
    setScale(clampScale(baseScale, minScale, maxScale));
  }, [clampScale, baseScale, minScale, maxScale]);

  return scale;
}

export function IsLandscapeAgent(props: Props): React.Node {
  const layoutState = useLayoutState();
  return !layoutState.portrait && props.children ? props.children : null;
}

export function IsPortraitAgent(props: Props): React.Node {
  const layoutState = useLayoutState();
  return layoutState.portrait && props.children ? props.children : null;
}

type PropsMobileWrapper = {
  safearea?: boolean,
  children?: React.Node,
};

export function LayoutWrapper(props: PropsMobileWrapper): React.Element<any> {
  const {children, ...others} = props;
  const layoutSize = useLayoutState();

  return (
    <DivWrapper {...others} datawidth={layoutSize.width} dataheight={layoutSize.height}>
      {children}
    </DivWrapper>
  );
}

type PropsLayout = {
  children?: React.Node,
  minScale?: number,
  maxScale?: number,
  dataPaddingTop?: number,
};

export function LayoutColumn(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivColumn {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivColumn>
  );
}

export function LayoutRow(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivRow {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivRow>
  );
}

export function LayoutInline(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <StyledDiv {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </StyledDiv>
  );
}

export function LayoutLeft(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivLeft {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivLeft>
  );
}

export function LayoutRight(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivRight {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivRight>
  );
}

export function LayoutRightMiddle(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivRightMiddle {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivRightMiddle>
  );
}


export function LayoutCenterMiddle(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivCenterMiddle {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivCenterMiddle>
  );
}

export function LayoutTop(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivTop {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivTop>
  );
}

export function LayoutCenterTop(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivCenterTop {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivCenterTop>
  );
}

export function LayoutBottom(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivBottom {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivBottom>
  );
}

export function LayoutCenterBottom(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivCenterBottom {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivCenterBottom>
  );
}

export function LayoutLeftTop(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivLeftTop {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivLeftTop>
  );
}

export function LayoutLeftBottom(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivLeftBottom {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivLeftBottom>
  );
}

export function LayoutRightBottom(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivRightBottom {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivRightBottom>
  );
}

export function LayoutRightTop(props: PropsLayout): React.Element<any> {
  const {children, minScale, maxScale, ...others} = props;
  const layoutSize = useLayoutState();
  const scale = useLayoutScale(layoutSize.scale, minScale, maxScale);
  return (
    <DivRightTop {...others} dataMobile={layoutSize.mobile} dataScale={scale}>
      {children}
    </DivRightTop>
  );
}

const DivWrapper: React.AbstractComponent<any> = styled.div`
  position: absolute;
  left: calc((100% - ${(props) => isMobileSafari ? `${props.datawidth}px` : '100%'}) * 0.5);
  right: calc((100% - ${(props) => isMobileSafari ? `${props.datawidth}px` : '100%'}) * 0.5);
  top: 0px;
  bottom: calc((100% - ${(props) => isMobileSafari ? `${props.dataheight}px` : '100%'}));


  > * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
`;

const DivColumn: React.AbstractComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.dataPaddingTop ?? 0}px;
  > div {
    transform: ${(props) => props.dataScale !== undefined ? `scale(${props.dataScale})` : 'initial'};
  }
`;

const DivRow: React.AbstractComponent<any> = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${(props) => props.dataPaddingTop ?? 0}px;
  > div {
    transform: ${(props) => props.dataScale !== undefined ? `scale(${props.dataScale})` : 'initial'};
  }
`;

const StyledDiv: React.AbstractComponent<any> = styled.div`
  transform: ${(props) => props.dataScale !== undefined ? `scale(${props.dataScale})` : 'initial'};
  padding-top: ${(props) => props.dataPaddingTop ?? 0}px;
`;

const DivLeft: React.AbstractComponent<any> = styled(StyledDiv)`
  width: auto;
  height: 100%;

  position: absolute;
  left: ${(props) => isChrome && isMobile && isIOS ?20:0 }px;
  top: 0px;

  transform-origin: left center;
`;

const DivRight: React.AbstractComponent<any> = styled(StyledDiv)`
  width: auto;
  height: 100%;

  position: absolute;
  right: ${(props) => isChrome && isMobile && isIOS ?20:0 }px;
  top: 0px;

  transform-origin: right center;
`;

const DivRightMiddle: React.AbstractComponent<any> = styled(DivRight)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DivCenterMiddle: React.AbstractComponent<any> = styled(StyledDiv)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
  transform-origin: center center;
`;

const DivTop: React.AbstractComponent<any> = styled(StyledDiv)`
  width: 100%;
  transform-origin: center top;
`;

const DivCenterTop: React.AbstractComponent<any> = styled(DivTop)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center top;
`;

const DivBottom: React.AbstractComponent<any> = styled(StyledDiv)`
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform-origin: center bottom;
`;

const DivCenterBottom: React.AbstractComponent<any> = styled(DivBottom)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center bottom;
`;

const DivLeftTop: React.AbstractComponent<any> = styled(StyledDiv)`
  position: absolute;
  left: ${(props) => isChrome && isMobile && isIOS ?20:0 }px;
  top: 0px;
  transform-origin: left top;

`;

const DivLeftBottom: React.AbstractComponent<any> = styled(StyledDiv)`
  position: absolute;
  left: ${(props) => isChrome && isMobile && isIOS ?20:0 }px;

  bottom: 0px;
  transform-origin: left bottom;
`;

const DivRightBottom: React.AbstractComponent<any> = styled(StyledDiv)`
  position: absolute;
  right: ${(props) => isChrome && isMobile && isIOS ?20:0 }px;
  bottom: 0px;
  transform-origin: right bottom;
`;

const DivRightTop: React.AbstractComponent<any> = styled(StyledDiv)`
  position: absolute;
  right: ${(props) => isChrome && isMobile && isIOS ?20:0 }px;
  top: 0px;
  transform-origin: right top;
`;

const CalcVideoSize = (mobile: boolean, tablet: boolean, width: number, height: number): Array<number> => {
  let videoWidth = width;
  let videoHeight = Math.floor(width / 16 * 9);
  if (tablet) {
    return [videoWidth, videoHeight];
  }
  if (!mobile && !tablet) {
    videoHeight = height;
    videoWidth = Math.floor(height / 9 * 16);
    // if (width > DesktopWidthMax) {
    //   videoWidth = DesktopWidthMax;
    //   videoHeight = Math.floor(DesktopWidthMax / 16 * 9);
    // }
    
    return [videoWidth, videoHeight];
  }
  if (videoHeight > height) {
    videoHeight = mobile ? height : height - ControllerHeight.desktop;
    videoWidth = Math.ceil(videoHeight / 9 * 16);
  }
  else if (!mobile && videoHeight + ControllerHeight.desktop > height) {
    videoHeight = height - ControllerHeight.desktop;
    videoWidth = Math.ceil(videoHeight / 9 * 16);
  }
  return [videoWidth, videoHeight];
};

export {DivCenterMiddle, DivTop, DivBottom, DivRight};
