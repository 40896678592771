// @flow
import * as React from 'react';
import {Link, useParams} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import {useSessionState, fetchSettings, toLowerKeys} from 'components/Session';

type Props = {};

export default function NotFound(props: Props): React.Element<any> {
  const session = useSessionState();

  return (
    <Title
      textColor={session.contents.ui?.player_text_color}
      bgColor={session.contents.ui?.player_bg_color}>
      404 NOT FOUND
    </Title>
  );
}

function DevUrlList(props: Props): React.Element<any> {
  const session = useSessionState();
  const {countryCode} = useParams();
  const countryList = countryCode ? [countryCode] : [];
  return (
    <Wrapper>
      <GlobalStyle />
      <DivListUrl>
        [DEV]
        {countryList.map((country) => {
          return <ListUrls key={country} session={session} country={country} />;
        })}
        <ul></ul>
      </DivListUrl>
    </Wrapper>
  );
}

function ListUrls({session, country}) {
  const [settings, setSettings] = React.useState(session.settings);
  React.useEffect(() => {
    fetchSettings(country).then(res => res.json()).then(res => {
      setSettings(toLowerKeys(res)); 
    });
  }, [country])
  
  return (
    <div key={country}>
      <h2>{country} (model: {settings.model_codes.length}, color: {settings.color_codes.length})</h2>
      <ListUrl session={{settings: settings}} country={country} />
    </div>
  );
}

function ListUrl({session, country}) {
  if (session.settings.path_rules.includes("model")) {
    return (
      <>
        {session.settings.model_codes.map((model) => {
          return (
            <div key={model}>
              <li key={model}>
                <b>{session.settings.models[model].name}</b>
              </li>
              <li key={`${model}-color`}>
                <ul>
                  {session.settings.models[model].colors?.map((colorRaw) => {
                    const color = colorRaw.toLowerCase();
                    return (
                      <li key={`${model}-${color}`}>
                        {session.settings.contents[color]?.text?.name}{' '}
                        <Link
                          to={`/${country}/${model}/${color}`}>{`${window.location.origin}/${country}/${model}/${color}`}</Link>
                        <img src={`/contents/${country}/models/${model}/${model}-${color}.png`} alt={`${model}-${color}.png`}/>
                      </li>);
                  })}
                </ul>
              </li>
              <br />
            </div>
          );
        })}
      </>
    );
  }
  return (
    <>
      {session.settings.color_codes.map((color) => (
        <li key={color}>
          {session.settings.contents[color].text.name}{' '}
          <Link
            to={`/${country}/${color}`}>{`${window.location.origin}/${country}/${color}`}</Link>
          {session.settings.model_codes.map((model) => session.settings.models[model].colors?.map((colorRaw) => (colorRaw.toLowerCase() === color.toLowerCase() ? <img key={`${model}-${color}`} src={`/contents/${country}/models/${model}/${model}-${color}.png`} alt={`${model}-${color}.png`}/> : null)))}
        </li>
      ))}
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100%;
    overflow: auto;
  }
  main {
    position: relative;
    width: 100%;
    min-height: 100%;
  }

  #root {
    position: relative;
    width: 100%;
    min-height: 100%;
  }
`

const Title = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  pointer-events: auto;
  user-select: text;
`

const DivListUrl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    pointer-events: auto;
  }

  img {
    width: 14px;
    height: 14px;
  }
`;

export {DevUrlList}