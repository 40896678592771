// @flow
import * as React from 'react';
import styled, {keyframes} from 'styled-components';

type Props = {
  children?: React.Node,
  textColor: string,
  bgColor: string,
  onButtonClick?: (?Event) => void,
  onAnimationEnd?: (?Event) => void,
  className?: string,
  toTop?: boolean,
}

export function RectButton(props: Props): React.Element<any> {
  const {children, textColor, bgColor, onButtonClick, onAnimationEnd, className, toTop, ...others} = props;
  const [clickActive, setClickActive] = React.useState(false);
  const swipeToTop = React.useMemo(() => toTop ?? true, [toTop]);
  const buttonRef = React.useRef();

  const handleClick = React.useCallback((e: Event) => {
    e.stopPropagation();
    setClickActive(true);
    onButtonClick?.(e);
  }, [onButtonClick]);

  React.useEffect(() => {
    function handleAnimationEnd() {
      setClickActive(false);
      onAnimationEnd?.(undefined);
    }
    const wrapperDiv = buttonRef.current;
    if (wrapperDiv) {
      wrapperDiv.addEventListener("animationend", handleAnimationEnd);
    }
    return () => {
      wrapperDiv?.removeEventListener("animationend", handleAnimationEnd);
    }
  }, [buttonRef, props, onAnimationEnd]);

  return (
    <Button {...others} ref={buttonRef} className={clickActive ? `${className ?? ''} click-active` : className} textColor={textColor} bgColor={bgColor} onClick={handleClick} toTop={swipeToTop}>
      {children}
    </Button>
  );
}

export function UnderlineButton(props: Props): React.Element<any> {
  const {children, textColor, bgColor, onButtonClick, onAnimationEnd, className, toTop, ...others} = props;
  const [clickActive, setClickActive] = React.useState(false);
  const swipeToTop = React.useMemo(() => toTop ?? true, [toTop]);
  const buttonRef = React.useRef();

  const handleClick = React.useCallback((e: Event) => {
    e.stopPropagation();
    setClickActive(true);
    onButtonClick?.(e);
  }, [onButtonClick]);

  React.useEffect(() => {
    function handleAnimationEnd() {
      setClickActive(false);
      onAnimationEnd?.(undefined);
    }
    const wrapperDiv = buttonRef.current;
    if (wrapperDiv) {
      wrapperDiv.addEventListener("animationend", handleAnimationEnd);
    }
    return () => {
      wrapperDiv?.removeEventListener("animationend", handleAnimationEnd);
    }
  }, [buttonRef, props, onAnimationEnd]);

  return (
    <ButtonUnderlineAnimation {...others} ref={buttonRef} className={clickActive ? `${className ?? ''} click-active` : className} textColor={textColor} bgColor={bgColor} onClick={handleClick} toTop={swipeToTop}>
      {children}
    </ButtonUnderlineAnimation>
  );
}

const ButtonClickAnimation = (from, to) => keyframes`
  0% { color: ${from}; }
  34% { color: ${to}; }
  67% { color: ${to}; }
  100% { color: ${from}; }
`

const ButtonClickContentsAnimation = (from, to) => keyframes`
  0% { transform: translateY(${from}); opacity: 0;}
  34% { transform: translateY(0%); opacity: 1;}
  67% { transform: translateY(0%); opacity: 1;}
  100% { transform: translateY(${to}); opacity: 1;}
`

const Button = styled.button`
  color: ${props => props.textColor};
  border: 1px solid ${props => `${props.textColor}33`};

  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200%;
    background-color: ${props => props.textColor};
    z-index: -1;
    transform: translateY(${props => props.toTop ? 'calc(100% + 1px)' : 'calc(-100% - 1px)'});
  }

  &.click-active {
    animation-name: ${props => ButtonClickAnimation(props.textColor, props.bgColor)};
    animation-duration: 0.8s;
    animation-timing-function: ease;
    animation-fill-mode: none;

    &:before {
      animation-name: ${props => ButtonClickContentsAnimation(props.toTop ? '100%' : '-100%', props.toTop ? '-100%' : '100%')};
      animation-duration: 0.8s;
      animation-timing-function: ease;
      animation-fill-mode: none;
    }
  }
`

const ButtonClickContentsUnderlineAnimation = (from, to) => keyframes`
  0% { transform: translateX(${from});}
  33% { transform: translateX(0%);}
  67% { transform: translateX(0%);}
  100% { transform: translateX(${to});}
`
const ButtonUnderlineAnimation = styled.button`
  > span {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 200%;
      height: 100%;
      border-bottom: 1px solid ${props => props.textColor};
      z-index: -1;
      transform: translateX(-100%);
    }
  }

  &.click-active {
    > span {
      &:before {
        animation-name: ${props => ButtonClickContentsUnderlineAnimation('-100%', '100%')};
        animation-duration: 0.75s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: none;
      }
    }
  }
`
